<mat-sidenav-container fullscreen>
  <mat-sidenav opened [fixedInViewport]="true" mode="side">
    <mat-list class="side-nav">
      <h1 class="side-nav__title">{{ 'menu.title' | transloco }}</h1>

      <mat-list-item
        routerLink=""
        routerLinkActive="side-nav__item--active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="uil uil-home side-nav__icon"></i> {{ 'menu.dashboard' | transloco }}
      </mat-list-item>

      <mat-list-item class="side-nav__spacer"></mat-list-item>

      <mat-list-item routerLink="pool" routerLinkActive="side-nav__item--active">
        <i class="uil uil-users-alt side-nav__icon"></i> {{ 'menu.pool' | transloco }}
      </mat-list-item>

      <mat-list-item class="side-nav__spacer"></mat-list-item>

      <mat-list-item routerLink="tare-template" routerLinkActive="side-nav__item--active">
        <i class="uil uil-weight side-nav__icon"></i>{{ 'menu.tareTemplate' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="price-template" routerLinkActive="side-nav__item--active">
        <i class="uil uil-bill side-nav__icon"></i>{{ 'menu.price_template' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="note-template" routerLinkActive="side-nav__item--active">
        <i class="uil uil-weight side-nav__icon"></i>{{ 'menu.noteTemplate' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="certificate" routerLinkActive="side-nav__item--active">
        <i class="uil uil-award side-nav__icon"></i>{{ 'menu.certificate' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="origin" routerLinkActive="side-nav__item--active">
        <i class="uil uil-user-location side-nav__icon"></i>{{ 'menu.origin' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="contract-template" routerLinkActive="side-nav__item--active">
        <i class="uil uil-file-shield-alt side-nav__icon"></i>{{ 'menu.contract_templates' | transloco }}
      </mat-list-item>

      <mat-list-item class="side-nav__spacer"></mat-list-item>

      <mat-list-item routerLink="product" routerLinkActive="side-nav__item--active">
        <i class="uil uil-circle side-nav__icon"></i>{{ 'menu.product' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="product-variety" routerLinkActive="side-nav__item--active">
        <i class="uil uil-pentagon side-nav__icon"></i>{{ 'menu.product_variety' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="disease" routerLinkActive="side-nav__item--active">
        <i class="uil uil-coronavirus side-nav__icon"></i>{{ 'menu.disease' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="size_sorting" routerLinkActive="side-nav__item--active">
        <i class="uil uil-ruler side-nav__icon"></i>{{ 'menu.size_sorting' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="treatment" routerLinkActive="side-nav__item--active">
        <i class="uil uil-raindrops-alt side-nav__icon"></i>{{ 'menu.treatment' | transloco }}
      </mat-list-item>

      <mat-list-item class="side-nav__spacer"></mat-list-item>

      <mat-list-item routerLink="bulk-order" routerLinkActive="side-nav__item--active">
        <i class="uil uil-truck-loading side-nav__icon"></i>{{ 'menu.bulk_order' | transloco }}
      </mat-list-item>

      <mat-list-item class="side-nav__spacer"></mat-list-item>

      <mat-list-item routerLink="config" routerLinkActive="side-nav__item--active">
        <i class="uil uil-sliders-v side-nav__icon"></i>{{ 'menu.config' | transloco }}
      </mat-list-item>
      <mat-list-item routerLink="user" routerLinkActive="side-nav__item--active">
        <i class="uil uil-user side-nav__icon"></i>{{ 'menu.user' | transloco }}
      </mat-list-item>

      <mat-list-item class="side-nav__spacer side-nav__spacer--logout"></mat-list-item>

      <mat-list-item (click)="logout()" class="logout">
        <i class="uil uil-signout side-nav__icon"></i>{{ 'menu.logout' | transloco }}
      </mat-list-item>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container container--flex-direction-column container--flex-full-height">
      <router-outlet></router-outlet>
    </div>

    <div class="version">9ce3106</div>
  </mat-sidenav-content>
</mat-sidenav-container>
